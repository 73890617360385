var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-4" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-logo flex justify-items-center items-center mb-2 pt-4",
                          },
                          [
                            _c("img", {
                              staticClass: "mx-auto",
                              staticStyle: { "max-height": "25px" },
                              attrs: {
                                src: require("@/assets/images/logo/logo.png"),
                                alt: "logo",
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "mb-4 mt-4 grid justify-items-center",
                            },
                            [_vm._v(_vm._s(_vm.$t("recupere-sua-senha")))]
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "por-favor-informe-seu-endereco-de-email-"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          staticClass: "w-full mb-8",
                          attrs: {
                            "data-vv-validate-on": "blur",
                            type: "email",
                            "label-placeholder": "Email",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("email"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex flex-wrap justify-center mt-4" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-right px-4 w-full md:w-auto mt-3 mb-2 md:mt-0 md:mb-0 mr-4 ml-4",
                                attrs: { disabled: !_vm.validateForm },
                                on: { click: _vm.sendForgotInstruction },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "email_template_types.reset_password"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "px-4 w-full md:w-auto mr-4 ml-4",
                                attrs: { type: "border", to: "/login" },
                              },
                              [_vm._v(_vm._s(_vm.$t("voltar-para-login")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }